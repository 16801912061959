import React from "react"
import HelmetTitle from "../components/utils"
import { Container, Row, Col } from "reactstrap"
import Layout from "../components/Layout"
import "../scss/modules/_m-hero-traindex.scss"
import traindexImage from "../images/traindex-main-image.png"
import traindexLogo from "../images/traindex.png"

const Traindex = () => {
  const title = "Traindex",
    tagline = `Traindex digests unstructured data sets to uncover meaning and relationships in your content 
      so you gain a greater understanding of your data.`

  return (
    <Layout>
      <div className="traindex mb-6">
        <HelmetTitle title="Traindex" />
        <div className="traindex-banner pt-6 pb-6 mb-4 text-white text-justify">
          <Container fluid>
            <Row>
              <Col xl={{ size: 10, offset: 1 }}>
                <h1 className="mb-5 font-weight-bold">{title} </h1>
                <h3 className="mb-5 text-justify">{tagline}</h3>
              </Col>
            </Row>
          </Container>
        </div>
        <Container fluid>
          <Row>
            <Col
              xl={{ size: 4, offset: 1 }}
              md={{ size: 4, offset: 0 }}
              sm={{ size: 4, offset: 2 }}
              xs={{ offset: 2 }}
              className="traindex-img-col"
            >
              <img
                src={traindexImage}
                alt="Computer Logo"
                className="img-responsive traindex-img"
              />
            </Col>

            <Col
              xl={{ size: 6, offset: 1 }}
              lg={{ size: 6, offset: 1 }}
              md={{ size: 6, offset: 2 }}
              sm={{ size: 8, offset: 2 }}
              xs={{ offset: 1 }}
              className="traindex-col"
            >
              <Row className="mt-4 mb-5 d-none d-sm-none d-md-block" noGutters>
                <a
                  href="https://www.traindex.io/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={traindexLogo}
                    alt="Traindex Logo"
                    className="img-responsive traindex-logo"
                  />
                </a>
              </Row>
              <h4 className="font-weight-bold traindex-header mt-2">
                Reduce the time and cost of analyzing your data
              </h4>
              <br />
              <p>
                <i className="fa fa-check-circle pt-1 mr-2" />
                <span className="pl-4">
                  Extract key concepts, patterns, and relationships from large
                  volumes of textual content.
                </span>
              </p>
              <p>
                <i className="fa fa-check-circle pt-1 mr-2" />
                <span className="pl-4">
                  Spot trends in the textual content to understand consumer
                  sentiment.
                </span>
              </p>
              <p>
                <i className="fa fa-check-circle pt-1 mr-2" />
                <span className="pl-4">
                  Summarize content from documents and gain a semantic
                  understanding of the underlying content.
                </span>
              </p>
              <p>
                <i className="fa fa-check-circle pt-1 mr-2" />
                <span className="pl-4">
                  Text classification modules to assign a category to a text.
                </span>
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  )
}

export default Traindex
